import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  AreaData,
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetAreaData,
  GetAreaParams,
  GetRegionData,
  GetSingleDataParams,
  GetTerritoryData,
  GetTerritoryParams,
  Params,
  PostRequest,
  RegionData,
  TerritoryData,
} from "../../../../types/redux";

// create a region
export const createRegionAsync = createAsyncThunk<
  null,
  PostRequest<RegionData, CommonOthers>
>(
  "np-geo/createRegion",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createRegion, data);

      reRender(true);
      toast("success", "Region create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Region create fail");
      return rejectWithValue(error);
    }
  }
);

// update a region
export const updateRegionAsync = createAsyncThunk<
  null,
  PostRequest<RegionData, CommonOthers>
>(
  "np-geo/updateRegion",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.updateRegion}/${id}`, data);

      reRender(true);
      toast("success", "Region update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Region update fail");
      return rejectWithValue(error);
    }
  }
);

// get all Region
export const getAllRegionAsync = createAsyncThunk<
  GetRegionData[],
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getAllRegion", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllRegion, {
      params,
    });

    return data.data?.getData as GetRegionData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get region fail");
    return rejectWithValue(error);
  }
});

// get single Region
export const getSingleRegionAsync = createAsyncThunk<
  GetRegionData,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getSingleRegion", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.getSingleRegion}/${params?.id}`);

    return data.data?.getData as GetRegionData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get region fail");
    return rejectWithValue(error);
  }
});

// get count Region
export const getCountRegionAsync = createAsyncThunk<
  number,
  undefined,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getCountRegion", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getCountRegion);
    return data.data?.total || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get region count data fail");
    return rejectWithValue(error);
  }
});

// create a area
export const createAreaAsync = createAsyncThunk<
  null,
  PostRequest<AreaData, CommonOthers>
>(
  "np-geo/createArea",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createArea, data);

      reRender(true);
      toast("success", "Area create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Area create fail");
      return rejectWithValue(error);
    }
  }
);

// update a Area
export const updateAreaAsync = createAsyncThunk<
  null,
  PostRequest<AreaData, CommonOthers>
>(
  "np-geo/updateArea",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.updateArea}/${id}`, data);

      reRender(true);
      toast("success", "Area update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Area update fail");
      return rejectWithValue(error);
    }
  }
);

// get all Area
export const getAllAreaAsync = createAsyncThunk<
  GetAreaData[],
  Params<GetAreaParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getAllArea", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllArea, {
      params,
    });

    return data.data?.getData as GetAreaData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get area fail");
    return rejectWithValue(error);
  }
});

// get single Area
export const getSingleAreaAsync = createAsyncThunk<
  GetAreaData,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getSingleArea", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.getSingleArea}/${params?.id}`);

    return data.data?.getData as GetAreaData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get area fail");
    return rejectWithValue(error);
  }
});

// get count Area
export const getCountAreaAsync = createAsyncThunk<
  number,
  undefined,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getCountArea", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getCountArea);
    return data.data?.total || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get area count data fail");
    return rejectWithValue(error);
  }
});

// create a territory
export const createTerritoryAsync = createAsyncThunk<
  null,
  PostRequest<TerritoryData, CommonOthers>
>(
  "np-geo/createTerritory",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createTerritory, data);

      reRender(true);
      toast("success", "Territory create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Territory create fail");
      return rejectWithValue(error);
    }
  }
);

// update a Territory
export const updateTerritoryAsync = createAsyncThunk<
  null,
  PostRequest<TerritoryData, CommonOthers>
>(
  "np-geo/updateTerritory",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.updateTerritory}/${id}`, data);

      reRender(true);
      toast("success", "Territory update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Territory update fail");
      return rejectWithValue(error);
    }
  }
);

// get all territory
export const getAllTerritoryAsync = createAsyncThunk<
  GetTerritoryData[],
  Params<GetTerritoryParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getAllTerritory", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllTerritory, {
      params,
    });

    return data.data?.getData as GetTerritoryData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get territory fail");
    return rejectWithValue(error);
  }
});

// get single Territory
export const getSingleTerritoryAsync = createAsyncThunk<
  GetTerritoryData,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getSingleTerritory", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(
      `${BASE_API.getSingleTerritory}/${params?.id}`
    );

    return data.data?.getData as GetTerritoryData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get territory fail");
    return rejectWithValue(error);
  }
});

// get count Territory
export const getCountTerritoryAsync = createAsyncThunk<
  number,
  undefined,
  {
    rejectValue: ErrorMessage;
  }
>("np-geo/getCountTerritory", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getCountTerritory);
    return data.data?.total || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get territory count data fail");
    return rejectWithValue(error);
  }
});
