export default function ExclamationIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_165_113281)">
        <path
          d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z"
          fill="#E82C4C"
        />
        <path
          d="M9.82129 15.3214C9.82129 15.634 9.94546 15.9338 10.1665 16.1548C10.3875 16.3758 10.6873 16.5 10.9999 16.5C11.3124 16.5 11.6122 16.3758 11.8332 16.1548C12.0543 15.9338 12.1784 15.634 12.1784 15.3214C12.1784 15.0089 12.0543 14.7091 11.8332 14.4881C11.6122 14.267 11.3124 14.1429 10.9999 14.1429C10.6873 14.1429 10.3875 14.267 10.1665 14.4881C9.94546 14.7091 9.82129 15.0089 9.82129 15.3214ZM10.4106 12.5714H11.5891C11.6972 12.5714 11.7856 12.483 11.7856 12.375V5.69643C11.7856 5.58839 11.6972 5.5 11.5891 5.5H10.4106C10.3025 5.5 10.2141 5.58839 10.2141 5.69643V12.375C10.2141 12.483 10.3025 12.5714 10.4106 12.5714Z"
          fill="#E82C4C"
        />
      </g>
      <defs>
        <clipPath id="clip0_165_113281">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
