import { useEffect, useState } from "react";
import { AUTH_SAVE } from "../helpers/config";

export default function useRememberMe<LoginData extends object>(
  forRemember = "",
  initialValues: LoginData
) {
  const [values, setValues] = useState<LoginData | null>(null);
  const [remember, setRemember] = useState(false);

  const storageName = `${AUTH_SAVE}${
    forRemember ? `-${forRemember}-` : "-"
  }login-remember`;

  const saveRemember = (data: LoginData) => {
    if (remember) {
      localStorage.setItem(storageName, JSON.stringify(data));
    } else {
      localStorage.removeItem(storageName);
    }
  };

  const handleRemember = (e: any) => {
    setRemember(e.target.checked);
  };

  useEffect(() => {
    const storedData = localStorage.getItem(storageName);

    if (storedData) {
      const rememberValue = JSON.parse(storedData);

      if (rememberValue && rememberValue?.remember) {
        const obj = {} as any;
        const valueData: string[] = Object.keys(initialValues);

        valueData?.forEach((el) => {
          obj[el] = rememberValue?.[el];
        });

        setRemember(rememberValue?.remember);
        setValues(obj);
      }
    }
  }, [initialValues, storageName]);

  return {
    remember,
    values,
    saveRemember,
    handleRemember,
  };
}
