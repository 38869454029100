export default function NotificationIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9681 17.082C16.8313 16.8614 18.6619 16.4217 20.4221 15.772C18.9314 14.1208 18.1078 11.9745 18.1111 9.75V9C18.1111 7.4087 17.4789 5.88258 16.3537 4.75736C15.2285 3.63214 13.7024 3 12.1111 3C10.5198 3 8.99365 3.63214 7.86843 4.75736C6.74321 5.88258 6.11107 7.4087 6.11107 9V9.75C6.11409 11.9746 5.29006 14.121 3.79907 15.772C5.53207 16.412 7.35907 16.857 9.25407 17.082M14.9681 17.082C13.0701 17.3071 11.1521 17.3071 9.25407 17.082M14.9681 17.082C15.1122 17.5319 15.148 18.0094 15.0727 18.4757C14.9973 18.942 14.8129 19.384 14.5345 19.7656C14.256 20.1472 13.8914 20.4576 13.4703 20.6716C13.0492 20.8856 12.5834 20.9972 12.1111 20.9972C11.6387 20.9972 11.173 20.8856 10.7519 20.6716C10.3308 20.4576 9.96614 20.1472 9.68769 19.7656C9.40924 19.384 9.22484 18.942 9.14949 18.4757C9.07413 18.0094 9.10997 17.5319 9.25407 17.082"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
