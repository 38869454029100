import { debounce } from "lodash";
import { useState } from "react";

export default function useDebounce(time: number = 500) {
  const [keyword, setKeyword] = useState("");
  const handleEnterValue = (value: string) => {
    setKeyword(value);
  };

  const delayedUpdate = debounce((value: string) => {
    setKeyword(value);
  }, time);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    delayedUpdate(newValue);
  };

  const handleInputSearch = (value: string) => {
    delayedUpdate(value);
  };

  return { keyword, handleInputChange, handleInputSearch, handleEnterValue };
}
