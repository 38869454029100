import { Button, ButtonProps } from "antd";
import { ReactNode } from "react";

type Props = {
  nextIcon?: ReactNode;
} & ButtonProps;

export default function IconButton({ nextIcon, children, ...rest }: Props) {
  return (
    <Button {...rest}>
      {children}
      {nextIcon ? <span className="ml-2">{nextIcon}</span> : null}
    </Button>
  );
}
