import { message } from "antd";
import { JointContent } from "antd/es/message/interface";

type Types = "success" | "error" | "info" | "warning";

export const toast = (type: Types, content: JointContent) => {
  message.config({
    top: 10,
    getContainer: () => {
      const container = document.createElement("div");
      container.className = "custom-message";
      document.body.appendChild(container);
      return container;
    },
  });

  switch (type) {
    case "success":
      message.success(content);
      break;
    case "error":
      message.error(content);
      break;
    case "info":
      message.info(content);
      break;
    case "warning":
      message.warning(content);
      break;
    default:
      message.info(content);
  }
};
