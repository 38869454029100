export default function SubLocation() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.539 15.341L7.542 15.344L7.544 15.346C7.67522 15.4454 7.83535 15.4993 8 15.4993C8.16465 15.4993 8.32478 15.4454 8.456 15.346L8.458 15.344L8.461 15.341L8.473 15.332C8.53744 15.2824 8.60079 15.2314 8.663 15.179C9.40862 14.5505 10.0936 13.8535 10.709 13.097C11.81 11.735 13 9.755 13 7.5C13 6.17392 12.4732 4.90215 11.5355 3.96447C10.5979 3.02678 9.32608 2.5 8 2.5C6.67392 2.5 5.40215 3.02678 4.46447 3.96447C3.52678 4.90215 3 6.17392 3 7.5C3 9.755 4.19 11.735 5.292 13.097C5.90739 13.8535 6.59239 14.5505 7.338 15.179C7.4003 15.2309 7.46331 15.2819 7.527 15.332L7.539 15.342V15.341ZM8 9C8.19698 9 8.39204 8.9612 8.57403 8.88582C8.75601 8.81044 8.92137 8.69995 9.06066 8.56066C9.19995 8.42137 9.31044 8.25601 9.38582 8.07403C9.4612 7.89204 9.5 7.69698 9.5 7.5C9.5 7.30302 9.4612 7.10796 9.38582 6.92597C9.31044 6.74399 9.19995 6.57863 9.06066 6.43934C8.92137 6.30005 8.75601 6.18956 8.57403 6.11418C8.39204 6.0388 8.19698 6 8 6C7.60218 6 7.22064 6.15804 6.93934 6.43934C6.65804 6.72064 6.5 7.10218 6.5 7.5C6.5 7.89782 6.65804 8.27936 6.93934 8.56066C7.22064 8.84196 7.60218 9 8 9Z"
        fill="currentColor"
      />
    </svg>
  );
}
