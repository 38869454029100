import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import CustomTable from "../../../components/common/CustomTable";
import Loader from "../../../components/common/Loader";
import Subheader from "../../../components/ui/Subheader";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { addKeyInArray } from "../../../helpers/siteConfig";
import { toast } from "../../../helpers/toast";
import AdminLayout from "../../../layouts/AdminLayout";
import { AddZero } from "../../../utils/number";
import { formatDate } from "../../../utils/time";

export default function Alarm() {
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;

  const columns: ColumnsType<any> = [
    {
      title: "#SL",
      align: "center",
      width: 60,
      render: (_, __, idx) => AddZero(idx + 1),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (val) => val?.toUpperCase(),
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      render: (val) => (val ? formatDate(val, "DD/MM/YYYY") : "N/A"),
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "alarm",
      width: 100,
      render: (val) => (
        <a download href={val}>
          <Button className="accent-text" type="text">
            Download
          </Button>
        </a>
      ),
    },
  ];

  const fetchData = async () => {
    if (isLoading || !hasMore) return; // Prevent fetching if already loading or no more data

    setIsLoading(true);

    const params = {
      limit: limit, // Fetch limit items at a time
      skip: limit * (page - 1), // Skip already fetched items
    };

    try {
      const response = await AXIOS.get(BASE_API.towerBulkMail, { params });
      const newData = Array.isArray(response?.data?.getData)
        ? response?.data?.getData
        : [];

      // Check if new data is empty and stop scrolling if no more data
      if (newData.length === 0) {
        setHasMore(false); // No more data available
      } else {
        // Only add new items if they don't already exist in the array
        setItems((prevItems) => {
          const uniqueItems = newData.filter(
            (item: any) =>
              !prevItems.some((prevItem) => prevItem._id === item._id)
          );
          return [...prevItems, ...uniqueItems];
        });
        setPage((prevPage) => prevPage + 1); // Increment page only if data is fetched
      }
    } catch (error) {
      toast("error", "Failed to fetch tower mail data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch initial data on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 10 &&
      !isLoading &&
      hasMore // Only fetch if there's more data available
    ) {
      fetchData();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMore]);

  return (
    <AdminLayout title="Alarm List" headerTitle="Alarm">
      <section className="shadow-sm mt-7">
        <Subheader title="Alarm List">
          {/* Additional subheader content */}
        </Subheader>

        <CustomTable<any>
          loading={false}
          total={items?.length}
          page={page}
          columns={columns}
          dataList={addKeyInArray(items)}
          isPagination={false} // Disable pagination since we're using infinite scroll
        />
      </section>
      <div className="flex justify-center items-center gap-1">
        {isLoading ? <Loader /> : null}
      </div>
    </AdminLayout>
  );
}
