import { useState } from "react";
import { ModalType } from "../types/common";

export default function useModal(reRender?: () => void) {
  const [open, setOpen] = useState<ModalType>({
    open: false,
    type: null,
    data: null,
  });

  const toggle = (api?: boolean) => {
    if (api && reRender) {
      reRender();
    }

    setOpen({
      open: false,
      type: null,
      data: null,
    });
  };

  const handleOpen = ({
    type = null,
    data = null,
  }: Omit<ModalType, "open">) => {
    setOpen({
      open: true,
      type,
      data,
    });
  };

  return { open, toggle, handleOpen };
}
