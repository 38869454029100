export default function EmailIcon() {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.375H16M1 1.375V12C1 12.1658 1.06585 12.3247 1.18306 12.4419C1.30027 12.5592 1.45924 12.625 1.625 12.625H15.375C15.5408 12.625 15.6997 12.5592 15.8169 12.4419C15.9342 12.3247 16 12.1658 16 12V1.375M1 1.375L8.5 8.25L16 1.375"
        stroke="#5E718D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
