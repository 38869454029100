export default function DeleteIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62518 4.875V7.875M4.37518 4.875V7.875M9.625 2.625L1.375 2.625M8.87518 2.625V9.75C8.87518 9.84946 8.83567 9.94484 8.76535 10.0152C8.69502 10.0855 8.59964 10.125 8.50018 10.125H2.50018C2.40073 10.125 2.30534 10.0855 2.23502 10.0152C2.16469 9.94484 2.12518 9.84946 2.12518 9.75V2.625M7.37518 2.625V1.875C7.37518 1.67609 7.29617 1.48532 7.15551 1.34467C7.01486 1.20402 6.8241 1.125 6.62518 1.125H4.37518C4.17627 1.125 3.98551 1.20402 3.84485 1.34467C3.7042 1.48532 3.62518 1.67609 3.62518 1.875V2.625"
        stroke="#FF3838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
