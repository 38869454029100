import { Button } from "antd";
import { ReactNode } from "react";
import ExclamationIcon from "../../assets/icons/ExclamationIcon";
import { CustomModalProps } from "../../types";
import CustomModal from "./CustomModal";

type Props = {
  data: any;
  deleteLoading: boolean;
  deleteTitle?: string;
  questionText?: string;
  buttonText?: string;
  handleYes: (val: any) => void;
  children?: ReactNode;
  alertClassName?: string;
  hideAlert?: boolean;
  closable?: boolean;
} & CustomModalProps;

export default function ConfirmModal({
  data,
  deleteLoading,
  deleteTitle = "Delete This.",
  questionText = "Are sure to delete this item?",
  buttonText = "Yes",
  alertClassName = "",
  handleYes,
  handleClose,
  disableCloseBtn,
  disabled,
  children,
  hideAlert,
  closable = false,
  ...rest
}: Props) {
  return (
    <CustomModal
      width={400}
      handleClose={handleClose}
      closable={closable}
      // title={null}
      {...rest}
    >
      <div className="p-6">
        {!hideAlert ? (
          <div className={`flex gap-4 ${alertClassName}`}>
            <ExclamationIcon />
            <div>
              <h4 className="text-body text-lg font-medium leading-normal relative -top-[2.5px]">
                {deleteTitle}
              </h4>
              <p className="text-body text-sm font-normal leading-normal mt-1">
                {questionText}
              </p>
            </div>
          </div>
        ) : null}

        {children}

        <div className="w-full justify-end flex gap-2 pt-6">
          <Button
            onClick={handleClose}
            disabled={disableCloseBtn}
            className="cancel_btn"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleYes(data)}
            type="primary"
            loading={deleteLoading}
            disabled={disabled}
            // className="!bg-status-waiting-btn hover:!bg-status-waiting-btn"
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
}
