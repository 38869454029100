import { Spin } from "antd";

type Props = {
  hideText?: boolean;
};

export default function Loader({ hideText = false }: Props) {
  return (
    <Spin
      className="w-full py-10"
      tip={!hideText && "Loading..."}
      size="default"
    >
      <span />
    </Spin>
  );
}
