export default function LeftArrowIcon() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1875 6H0.8125M0.8125 6L5.875 0.9375M0.8125 6L5.875 11.0625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
