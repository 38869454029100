import { useEffect } from "react";
import { setInnerWidth } from "../store/features/appSlice";
import { useAppDispatch } from "../store/store";

export default function useApp() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener("resize", function () {
      dispatch(setInnerWidth(window.innerWidth));
    });

    dispatch(setInnerWidth(window.innerWidth));
  }, [dispatch]);

  return null;
}
