import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllTowerData,
  GetNetworkLogData,
  NPTowerInitialStatus,
} from "../../../../types/redux";
import {
  cancelTowerTicketAsync,
  createTowerAsync,
  getAllCountTowerAsync2,
  getAllTowerAsync,
  getAllTowerAsync2,
  getCountTowerAsync,
  getSingleTicketAsync,
  networkLogViewAsync,
  removePGFromTowerTicketAsync,
  sendFeedbackAsync,
  towerBulkMailAsync,
  updateTowerAsync,
  uploadTicketExcelAsync,
} from "./networkAPI";

const initialState: NPTowerInitialStatus<
  GetAllTowerData,
  GetNetworkLogData,
  any
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allTowers: [],
  logNetworks: null,
  singleTower: null,
  towersCount: 0,
  bulkLoading: false,
  towerBulkMail: [],
};

const networkSlice = createSlice({
  name: "np-network",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create tower
    builder
      .addCase(createTowerAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createTowerAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createTowerAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all towers
    builder
      .addCase(getAllTowerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTowerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allTowers = action.payload;
      })
      .addCase(getAllTowerAsync.rejected, (state) => {
        state.loading = false;
        state.allTowers = [];
      });
    builder
      .addCase(getAllTowerAsync2.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTowerAsync2.fulfilled, (state, action) => {
        state.loading = false;
        state.allTowers = action.payload;
      })
      .addCase(getAllTowerAsync2.rejected, (state) => {
        state.loading = false;
        state.allTowers = [];
      });
    // get users tower
    builder
      .addCase(getCountTowerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountTowerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.towersCount = action.payload;
      })
      .addCase(getCountTowerAsync.rejected, (state) => {
        state.loading = false;
        state.towersCount = 0;
      });
    // get count tower 2
    builder
      .addCase(getAllCountTowerAsync2.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCountTowerAsync2.fulfilled, (state, action) => {
        state.loading = false;
        state.towersCount = action.payload;
      })
      .addCase(getAllCountTowerAsync2.rejected, (state) => {
        state.loading = false;
        state.towersCount = 0;
      });
    // get single tower
    builder
      .addCase(getSingleTicketAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(getSingleTicketAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleTower = action.payload;
      })
      .addCase(getSingleTicketAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleTower = null;
      });
    // update tower
    builder
      .addCase(updateTowerAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateTowerAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateTowerAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    builder
      .addCase(removePGFromTowerTicketAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(removePGFromTowerTicketAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(removePGFromTowerTicketAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    builder
      .addCase(cancelTowerTicketAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(cancelTowerTicketAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(cancelTowerTicketAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // get log view network
    builder
      .addCase(networkLogViewAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(networkLogViewAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.logNetworks = action.payload;
      })
      .addCase(networkLogViewAsync.rejected, (state) => {
        state.viewLoading = false;
        state.logNetworks = null;
      });
    // feedback user
    builder
      .addCase(sendFeedbackAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(sendFeedbackAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(sendFeedbackAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // create ticket with excel
    builder
      .addCase(uploadTicketExcelAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(uploadTicketExcelAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(uploadTicketExcelAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // bulk mail
    builder
      .addCase(towerBulkMailAsync.pending, (state) => {
        state.bulkLoading = true;
        state.towerBulkMail = [];
      })
      .addCase(towerBulkMailAsync.fulfilled, (state, action) => {
        state.bulkLoading = false;
        state.towerBulkMail = action.payload;
      })
      .addCase(towerBulkMailAsync.rejected, (state) => {
        state.bulkLoading = false;
        state.towerBulkMail = [];
      });
  },
});

export default networkSlice;
