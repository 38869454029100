export default function EmailUserIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="#F0F3F9" />
      <path
        d="M12.5 19.6699V28.2499C12.5 29.0456 12.8161 29.8086 13.3787 30.3712C13.9413 30.9339 14.7044 31.2499 15.5 31.2499H30.5C31.2956 31.2499 32.0587 30.9339 32.6213 30.3712C33.1839 29.8086 33.5 29.0456 33.5 28.2499V19.6699L24.572 25.1629C24.0992 25.4538 23.5551 25.6078 23 25.6078C22.4449 25.6078 21.9008 25.4538 21.428 25.1629L12.5 19.6699Z"
        fill="#8897AE"
      />
      <path
        d="M33.5 17.908V17.75C33.5 16.9544 33.1839 16.1913 32.6213 15.6287C32.0587 15.0661 31.2956 14.75 30.5 14.75H15.5C14.7044 14.75 13.9413 15.0661 13.3787 15.6287C12.8161 16.1913 12.5 16.9544 12.5 17.75V17.908L22.214 23.886C22.4504 24.0314 22.7225 24.1084 23 24.1084C23.2775 24.1084 23.5496 24.0314 23.786 23.886L33.5 17.908Z"
        fill="#8897AE"
      />
    </svg>
  );
}
