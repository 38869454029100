import { useState } from "react";
import * as XLSX from "xlsx";
import { formatDate } from "../utils/time";

export default function useExcelDownload<T>(downloadName?: string) {
  const [excelLoading, setExcelLoading] = useState(false);

  const handleExcelLoading = (val: boolean) => {
    setExcelLoading(val);
  };

  const downloadExcel = (data: T[]) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(
      wb,
      `${downloadName ? downloadName + "-" : ""}${formatDate(
        undefined,
        "DD-MM-YYYY"
      )}.xlsx`
    );

    setExcelLoading(false);
  };

  return {
    excelLoading,
    handleExcelLoading,
    downloadExcel,
  };
}
