import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  innerWidth: number;
  isCollapse?: boolean;
}

const initialState: InitialState = {
  innerWidth: 0,
  isCollapse: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInnerWidth: (state, action) => {
      state.innerWidth = action.payload;
    },
    setSidebarCollapse: (state, action) => {
      state.isCollapse = action.payload;
    },
  },
});

export const { setInnerWidth, setSidebarCollapse } = appSlice.actions;
export default appSlice;
