import { HTMLAttributes, ReactNode, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import NPFab from "../assets/images/fabIconNP.svg";
import SAFab from "../assets/images/fabIconSA.svg";
import { APP_NAME } from "../helpers/config";
import { stringToArray } from "../utils/array";

type Props = {
  title?: string;
  className?: string;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const AppLayout: React.FC<Props> = ({
  title,
  className = "",
  children,
  ...rest
}) => {
  const location = useLocation();
  const isSA = stringToArray(location.pathname, "/")?.[0] === "sa";

  useEffect(() => {
    // const root = document.getElementById("root");
    const body = document.getElementsByTagName("body")[0];

    if (isSA) {
      body?.classList.add("sa_panel");
    }
  }, [isSA]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="icon" href={isSA ? SAFab : NPFab} />
        <title>{title ? `${title} | ${APP_NAME}` : APP_NAME}</title>
      </Helmet>

      <div className={`${className}`} {...rest}>
        {children}
      </div>
    </HelmetProvider>
  );
};

export default AppLayout;
