import { Button, Slider } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import CompleteIcon from "../../../assets/icons/CompleteIcon";
import {
  networkLogViewAsync,
  sendFeedbackAsync,
} from "../../../store/features/NP/network/networkAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetAllTowerData } from "../../../types/redux";
import { capitalize } from "../../../utils/string";
import { calculateMinutes, minutesToHours } from "../../../utils/time";
import AvatarCard from "../../ui/AvatarCard";
import LocatorListItem from "../../ui/LocatorListItem";
import ServiceDuration from "../../ui/ServiceDuration";
import ServiceHistory, { HistoryListItem } from "../../ui/ServiceHistory";
import NetworkActionModal from "./NetworkActionModal";

type Props = {
  open: boolean;
  handleClose: (val?: boolean) => void;
  reRender: (val: boolean) => void;
  data?: any;
};

const defaultRatingValue = 5;
const maximumRatingValue = 5;

export default function Feedback({ open, data, reRender, handleClose }: Props) {
  const dispatch = useAppDispatch();
  const [ratingValue, setRatingValue] = useState(defaultRatingValue);
  const { updateLoading } = useAppSelector((state) => state.network);
  const { logNetworks } = useAppSelector((state) => state.network);
  const ticketData = data as GetAllTowerData;

  const time = {
    start: ticketData?.towerTicketLogData?.pgStart?.time,
    end: ticketData?.towerTicketLogData?.pgOff?.time,
  };

  const onSubmit = () => {
    const submittedData = {
      minutesOfWork:
        time?.start && time?.end ? calculateMinutes(time?.start, time?.end) : 0,
      totalAmount:
        time?.start && time?.end
          ? calculateMinutes(time?.start, time?.end) *
          (ticketData?.towerTicketLastData?.perMinuteCharge || 0)
          : 0,
      rating: ratingValue,
    };

    dispatch(
      sendFeedbackAsync({
        data: submittedData,
        others: {
          reRender,
          id: ticketData?.towerTicketLogData?._id,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      networkLogViewAsync({
        params: { id: ticketData?.towerTicketLogData?._id as string },
      })
    );
  }, [dispatch, ticketData?.towerTicketLogData?._id]);

  return (
    <NetworkActionModal
      title="Site Locator"
      locatorTitle={`${capitalize(ticketData?.towerName)} - ${ticketData?.regionName
        }`}
      iconColor="bg-primary-np"
      open={open}
      handleClose={handleClose}
      locatorChildren={
        <>
          <LocatorListItem title="Site Code:">
            {ticketData?.siteCode}
          </LocatorListItem>
          <LocatorListItem title="Location:">
            {capitalize(ticketData?.towerName)}
          </LocatorListItem>
          <LocatorListItem title="Region:">
            {ticketData?.regionName}
          </LocatorListItem>
          <LocatorListItem title="Area:">
            {ticketData?.areaName}
          </LocatorListItem>
          <LocatorListItem title="Territory:">
            {ticketData?.teritoryName}
          </LocatorListItem>
          <LocatorListItem title="Inactive Time:">
            {logNetworks?.pgAlert?.startTime?.status === "start" &&
              logNetworks?.pgAlert?.startTime?.time
              ? moment(
                logNetworks?.pgAlert?.startTime?.time,
                "HH:mm:ss"
              ).format("hh:mm:ss A")
              : "N/A"}
            <br />
            {logNetworks?.pgAlert?.stopTime?.status === "stop" &&
              logNetworks?.pgAlert?.stopTime?.time
              ? moment(
                logNetworks?.pgAlert?.stopTime?.time,
                "HH:mm:ss A"
              ).format("hh:mm:ss A")
              : "N/A"}
          </LocatorListItem>
          {ticketData?.towerTicketLogData?.missCall ? <>
            <LocatorListItem title="Miss Call Time:">
              {ticketData?.towerTicketLogData?.missCallTime}
            </LocatorListItem>
            <LocatorListItem title="Miss Call Note:">
              {ticketData?.towerTicketLogData?.note || "N/A"}
            </LocatorListItem>
          </> : null}
          {/* <LocatorListItem title="Electricity Status:">
            {"N/A"}
            <br />
            {"N/A"}
          </LocatorListItem> */}
        </>
      }
    >
      <div>
        <ServiceDuration
          icon={<CompleteIcon />}
          statusTitle="Task Completed"
          statusClass="text-primary-np"
          time={
            time?.start && time?.end
              ? minutesToHours(calculateMinutes(time?.start, time?.end))
              : `00:00`
          }
        />

        <ServiceHistory>
          <HistoryListItem title="Agent ID:">N/A</HistoryListItem>
          <HistoryListItem title="Start time:">
            {time?.start || "N/A"}
          </HistoryListItem>
          <HistoryListItem title="Off Time:">
            {time?.end || "N/A"}
          </HistoryListItem>
          <HistoryListItem title="Service Charge:">
            <h4 className="text-status-waiting-btn text-lg font-semibold">
              {time?.start && time?.end
                ? calculateMinutes(time?.start, time?.end) *
                (ticketData?.towerTicketLastData?.perMinuteCharge || 0)
                : 0}{" "}
              BDT
            </h4>
          </HistoryListItem>
        </ServiceHistory>

        <div className="rounded-lg border border-indigo-300 border-solid bg-white px-3.5 py-2.5 mb-5 select-none">
          <AvatarCard
            name={`${ticketData?.PgData?.firstName} ${ticketData?.PgData?.lastName}`}
            // id="O-56352"
            // time="12:35 PM"
            email={ticketData?.PgData?.email}
            showRating
            rating={ticketData?.PgData?.numberOfTask}
            ratingValue={
              ticketData?.PgData?.rating / ticketData?.PgData?.numberOfTask
            }
          />

          <h4 className="text-header text-lg font-medium mt-3">
            Give a Rating
          </h4>
          <p className="flex items-center justify-between gap-2 text-sm font-medium text-body mt-2 mb-3">
            <span className="text-status-info-text">
              Please rate your experience out of 5 stars
            </span>
            <span>
              {ratingValue}/{maximumRatingValue}
            </span>
          </p>

          <Slider
            min={0}
            max={maximumRatingValue}
            className="custom_range"
            onChange={setRatingValue}
            value={ratingValue}
          />
        </div>

        <Button
          type="primary"
          size="large"
          disabled={ratingValue === 0 || updateLoading}
          onClick={onSubmit}
          block
          loading={updateLoading}
        >
          Submit
        </Button>
      </div>
    </NetworkActionModal>
  );
}
