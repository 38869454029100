export function capitalize(value: string) {
  let capitalizedText = "";
  const conjunctions = [
    "an",
    "a",
    "the",
    "of",
    "and",
    "but",
    "for",
    "nor",
    "or",
    "so",
    "yet",
    "in",
    "on",
    "at",
    "to",
    "by",
    "with",
  ];

  const textArray = value.split(" ");

  for (let i = 0; i < textArray.length; i++) {
    let word = textArray[i];
    if (i === 0 || word.endsWith(".")) {
      capitalizedText +=
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
    } else if (conjunctions.includes(word.toLowerCase())) {
      capitalizedText += word.toLowerCase() + " ";
    } else {
      capitalizedText +=
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
    }
  }

  return capitalizedText.trim();
}

export function firstChar(value: string) {
  return value.charAt(0).toUpperCase();
}

export function removeQuotes(input: string) {
  return input.replace(/"/g, "");
}

export function sliceString(str: string = "", size: number, dot?: string) {
  const splitStr = str?.split(".");
  splitStr.pop();
  const newStr = splitStr?.join();

  return newStr?.length > size
    ? `${newStr?.slice(0, size)}${dot || ""}`
    : newStr;
}
